//<editor-fold desc=" ">
//</editor-fold>

.noClick {
  pointer-events: none !important;
}
.flexTruncate {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#background {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-bottom: 46.7%;
  background: #2c2c2c;
  animation: 20s linear 0s normal none infinite animate;
}

$small: 900px;
$medium: 1200px;

//<editor-fold desc="Tag">
//Chrome Hack, delays fill color of input for autofill function.
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: color 9999s ease-out, background-color 9999s ease-out;
  -webkit-transition-delay: 9999s;
}

*, *:before, *:after {
  //font-size: 10px;
  user-select: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

}
*:active {
  outline: 0;
}

//disable number spinner
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#root {
  display: flex;
  height: 100vh;
}
html {
  background-color: rgba(255,255,255,0);
  overflow: hidden;
}

body {
  margin: 0;
  font-size: 16px;
  font-family: sans-serif;
  text-align: center;
}

canvas {
  width: 100%;
}

hr {
  height: 1px;
  background-color: white;
  border: none;
}


ul {
  padding: 0; //Firefox needs this..?
  margin: 0;
  li:nth-child(odd) {
    background-color: #343434;
  }
  li:nth-child(even) {
    background-color: #2f2f2f;
  }
}
ul, li {
  list-style-type: none;
  -webkit-padding-start: 0;
}
li {
  //padding: 0 5px;
}
brow
Link {
  text-decoration: none;
}
a {
  &:hover {
    color: #4FA0E6;
  }
}

.alternativeInput {
  @extend input;
  border: 0 !important;
  background-color: #404040 !important;
  &:active, &:focus {
    background-color: #4d4d4d !important;
  }
}

input {
  display: flex;
  border: none;
  background-color: transparent;
  font-size: 18px;
  color: white;
  &:active, &:focus {
    outline: none;
  }
}

input[type="checkbox"]{
  display: none;
  &:active, &:focus {
    outline: 2px solid rgba(0,0,0,.5);
  }
}

progress[value] {
  /* Reset the default appearance */
  -webkit-appearance: none;
  appearance: none;
}
progress[value]::-webkit-progress-bar {
  background-color: transparent;
}
progress[value]::-webkit-progress-value {
  background-color: #232323;
}

button {
  padding: 0;
  background-color: transparent;
  border-style: none;
  outline: none;
  cursor: pointer;
}
*:focus {outline:0;}


select {
  border: 1px solid #2A2A2A;
  color: white;
  background-color: #34495e;
  border-radius: 2px;
  padding: 5px 10px;
  margin: 0 5px;
  outline: 0;
  -webkit-appearance: none; // hides dropdown arrow
  cursor: pointer;
}

textarea {
  outline: 0;
  resize: none;
  border: 0;
  background-color: #2c3e50;
  font-size: 20px;
  padding: 10px;
  &::placeholder {
    color: gray;
  }
}

//</editor-fold>


.boxShadow {
  box-shadow: 0 1px 3px 0
  rgba(0,0,0,.2), 0 1px 1px 0
  rgba(0,0,0,.15), 0 2px 1px -1px
  rgba(0,0,0,.13)
}
.buttonZoom {
  cursor: pointer;
  &:hover:not(:focus) {
    transform: scale(1.3) !important;
  }
  &:focus:not(:hover) {
    transform: scale(1.3) !important;
  }
}
.disabled {
  opacity: .5;
  cursor: default !important;
  pointer-events: none;
  background-color: #3A3A3A;
}

//<editor-fold desc="Scaffold">
.windowed {
  z-index: 3;

  background-color: #2f2f2f;
  display: flex;
  flex-direction: column;
  border: 1px solid black;

  align-items: center;
  justify-content: center;

  > div {
    width: 100%;
  }

  @media screen and (min-width: $medium) {
    margin: 5% 12%;
  }
  @media screen and (max-width: $small) {
    height: 100%;
  }
}
.fullScreen {
  z-index: 3;

  border-right: 1px solid black;
  border-left: 1px solid black;
  background-color: #2f2f2f;
  display: flex;
  flex-direction: column;
  height: 100%;
}
//</editor-fold>

.headsUpText {
  width: 100%;
  justify-content: center;
  font-family: monospace;
  color: white;
  font-size: 40px;
  font-weight: bold;
}

.fontLarge {
  font-size: 20px;
  font-weight: bold;
}
.fontStandard {
  font-size: 12px;
}


input:checked + .slider {
  background-color: #629062;
}
input:focus + .slider {
  box-shadow: 0 0 1px #629062;
}
input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
/* Rounded sliders */
.slider.round {
  border-radius: 2px;
}
.slider.round:before {
  border-radius: 2px;
}
//</editor-fold>
//<editor-fold desc="Button">
.buttonNext {
  @extend .buttonZoom;
  opacity: .2;
  &:hover {
    opacity: 1;
    color: white;
  }
}

.shopButton {
  height: 100%;
  display: flex;
  align-items: center;
  background-color: #404040;
  padding: 5px 10px;
  &:hover {
    background-color: #6e6e6e;
  }
}
.buttonGenericCenter {
  @extend .buttonGeneric;
  justify-content: center !important;
  padding: 0;
  font-size: 14px;
}
.buttonGeneric {
  font-size: 25px;
  color: #6b6b6b;
  background-color: #343434;
  display: flex;
  flex-grow: 1;
  align-items: center;
  cursor: pointer;
  &:hover {
    color: #B0B0B0;
    background-color: #383838;
  }
}
.buttonDisabled {
  cursor: inherit;
  color: #2f2f2f;
  &:hover {
    background-color: #343434;
  }
}
.buttonStandard {
  border: 2px solid #343434;
  border-radius: 4px;
  font-weight: bold;
  color: #808080;
  &:disabled {
    background-color: #5d5d5d;
    border: 6px solid #4e4e4e;
  }
  &:hover {
    color: #404040;
    background-color: #5d5d5d;
    border: 6px solid #4e4e4e;
  }
}
.buttonNeutral {
  display: inline-block;
  color: white;
  font-size: 14px;
  border-radius: 3px;
  padding: 5px 10px 5px 10px;
  font-weight: bold;
  background-color: #2f2f2f;

  &:hover {
    cursor: pointer;
  }
}
.standardButton {
  box-shadow: 0 1px 3px 0
  rgba(0,0,0,.2), 0 1px 1px 0
  rgba(0,0,0,.15), 0 2px 1px -1px
  rgba(0,0,0,.13);

  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 16px;
  padding: 10px;
  background-color: #343434;
  border: 1px solid #404040;
  color: #6b6b6b;
  &:hover {
    cursor: pointer;
    background-color: #404040;
    color: white;
  }
}
//</editor-fold>
//<editor-fold desc="Scrollbars">
.styleDarkScrollSquare {
  overflow-y: auto;
}
.styleDarkScrollSquare::-webkit-scrollbar-track {
  background-color: #343434;
}
.styleDarkScrollSquare::-webkit-scrollbar {
  width: 4px;
  background-color: #404040;
}
.styleDarkScrollSquare::-webkit-scrollbar-thumb {
  background-color: #808080;
}
::-webkit-scrollbar-corner { background: rgba(0,0,0,0.0); } //Corners may be white without this
//</editor-fold>
//<editor-fold desc="Item Card">

//</editor-fold>

.circle {
  border-radius: 50%;
  width: 25px;
  height: 25px;
}
.triangle {
  width: 0;
  height: 0;
  border-top:    10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left:   10px solid white;
  z-index: 4;
}
.slime {
  background-color: #2b2b2b;
  width: calc(50%);
  height: calc(50%);
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
}


//<editor-fold desc="Animations">
//Other animations can be found in animate.css CDN
.animatedFast {
  animation-duration: .2s;
}
.animatedQuick {
  animation-duration: .4s;
}
.animatedSlow {
  animation-duration: 1s !important;
}
.animatedSlowButNotSlower {
  animation-duration: 4s !important;
}
.animatedSlower {
  animation-duration: 20s !important;
}

.transitionSlow {
  transition: all         .5s ease;
  -o-transition: all      .5s ease;
  -moz-transition: all    .5s ease;
  -webkit-transition: all .5s ease;
}
.transitionFast {
  transition: all         .25s ease;
  -o-transition: all      .25s ease;
  -moz-transition: all    .25s ease;
  -webkit-transition: all .25s ease;
}

.fadeIn {
  animation-fill-mode: forwards;
}

.fadeInOut {
  opacity: 1;
  animation: fade 2s linear forwards;
}
.fadeOutForeverFast {
  opacity: 1;
  animation: fadeOut .5s linear forwards;
}

.fadeOutForever {
  opacity: 1;
  animation: fadeOut 2s linear forwards;
}
.fadeOutForeverSlow {
  opacity: 1;
  animation: fadeOut 4s linear forwards;
}

@keyframes fade {
  0%,100% { opacity: 0 }
  50% { opacity: 1 }
}
@keyframes fadeOut {
  0% { opacity: 1 }
  100% { opacity: 0 }
}

.floating {
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
}

@keyframes floating {
  from { transform: translate(0,  0px); }
  65%  { transform: translate(0, 2px); }
  to   { transform: translate(0, -0px); }
}

.animationFaderLoadText {
  animation: blinker 4s ease-in infinite;
  animation-delay: 2s;
}
.animationFaderSlotsText {
  animation: blinker 4s ease-in infinite;
}

.animationSpinSlow {
  transform-origin: 50% 50%;
  animation: spin 20s;
  backface-visibility: visible !important;
  animation-name: spin;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.animationSpin {
  transform-origin: 50% 50%;
  animation: spin 10s;
  backface-visibility: visible !important;
  animation-name: spin;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.animationSpinFast {
  transform-origin: 50% 50%;
  animation: spinReverse 1s;
  backface-visibility: visible !important;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.animationSpinReverse {
  transform-origin: 50% 50%;
  animation: spinReverse 10s;
  backface-visibility: visible !important;
  animation-name: spinReverse;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes spin {
  from { transform: rotate(360deg); }
  to { transform: rotate(0deg); }
}
@keyframes spinReverse {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.animationSideFlip {
  animation: flip .375s ease-in;
  backface-visibility: visible !important;
  animation-name: sideFlip;
}
.animationFlip {
  animation-delay: 2s;
  animation: flip .75s;
  backface-visibility: visible !important;
  animation-name: flip;
}

.animationFlipInfinite {
  animation: 2s flip infinite;
  animation-timing-function: linear;
  backface-visibility: visible !important;
  animation-name: flip;
}

@keyframes blinker {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}
@keyframes flip {
  from {
    transform: perspective(512px) rotate3d(0, 1, 0, 0deg);
  }
  to {
    transform: perspective(400px) rotate3d(0, 1, 0, 360deg);
  }
}
@keyframes sideFlip {
  from {
    transform: perspective(400px) rotate3d(0, 1, 0, 0deg);
  }
  to {
    transform: perspective(400px) rotate3d(0, 1, 0, 180deg);
  }
}

.panBackdrop {
  animation: slide 20s linear infinite;
}

@keyframes slide {
  from { background-position: 0 0; }
  to { background-position: -512px 512px; }
}


//<editor-fold desc="Unread Chat Message Icon Bounce">
.smallBounce {
  animation-name: smallBounce;
  transform-origin: center bottom;
}
@keyframes smallBounce {
  from, 20%, 53%, 80%, to {
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    transform: translate3d(0,0,0);
  }

  40%, 43% {
    animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    transform: translate3d(0, -6px, 0);
  }

  70% {
    animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    transform: translate3d(0, -4px, 0);
  }

  90% {
    transform: translate3d(0,-2px,0);
  }
}
//</editor-fold>
//</editor-fold>
.float {
  animation: float 15s ease-in-out infinite !important;
}
@keyframes float {
  0% {
    //box-shadow: 0 5px 15px 0px rgba(0,0,0,0.3);
    transform: translatey(0px);
  }
  50% {
    //box-shadow: 0 15px 15px 0px rgba(0,0,0,0.1);
    transform: translatey(-20px);
  }
  100% {
    //box-shadow: 0 5px 15px 0px rgba(0,0,0,0.3);
    transform: translatey(0px);
  }
}
@keyframes bounce {
  0% {
    transform: translatex(0);
  }
  50% {
    transform: translatex(.1em);
  }
  100% {
    transform: translatex(0);
  }
}
.containerItem {
  padding: 0 6px;
  margin-top: 1px;
  display: flex;
  flex-grow: 1;
  align-items: center;

    &:active {
      img {
        width: 90px !important;
      }
    }
}
.nav {
  display: flex;
  flex-direction: column;
  z-index: 3; //was 1 possibly for reasons
  flex-grow: 1;
}
.track {
  width: 100%;
  background: #2A2A2A;
  overflow: hidden;
}
.chatWindow {
  .iconChat {
    opacity: .5;
  }
  position: absolute;
  right: 0;
  .chatInput {
    border: none;
    &:focus {
      background-color: #404040;
    }
  }
  .iconChat {
    margin-right: 5px;
  }
}
.chatInput {
  flex-grow: 1;
  background-color: rgba(47, 47, 47, 0);
  font-size: 18px;
  padding: 4px;
  border: 1px solid rgb(47,47,47);
  box-shadow: 0 0 0 1px #5d5d5d;
  &:focus,&:active {
    outline: none;
    border: 1px solid white;
  }
  &:disabled {
    background: #505050;
  }
}

@keyframes deliveryTruck {
  from {
    fill: red;
    left: -25%;
  }
  to {
    fill: yellow;
    left: 100%;
  }
}

.commonGradient {
  background-image: linear-gradient(-141deg, rgba(80,80,80,.5) 15%, rgba(42,42,42,.2) 55%);
}
.unusualGradient {
  background-image: linear-gradient(-141deg, rgba(78,129,54,.5) 15%, rgba(42,42,42,.2) 55%);
}
.remarkableGradient {
  background-image: linear-gradient(-141deg, rgba(62,100,254,.5) 15%, rgba(42,42,42,.2) 55%);
}
.extraordinaryGradient {
  background-image: linear-gradient(-141deg, rgba(123,51,213,.5) 15%, rgba(42,42,42,.2) 55%);
}
.legendaryGradient {
  background-image: linear-gradient(-141deg, rgba(128,9,9,.5) 15%, rgba(42,42,42,.2) 55%);
}

.List {
  box-sizing: border-box;
}
.Row {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  //padding: 0 0.5rem;
  box-sizing: border-box;
}
.Item {
  width: 100px;
  height: 100px;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid #eee;
}

@keyframes rainbow-color-shift-fill {
  @for $i from 0 through 360 {
    #{percentage($i/360)} {
      fill: hsl($i,100%,50%);
    }
  }
}

@keyframes rainbow-color-shift-background {
  @for $i from 0 through 360 {
    #{percentage($i/360)} {
      background: hsla($i,100%,50%,.1);
    }
  }
}

@keyframes rainbow-color-shift-color {
  @for $i from 0 through 360 {
    #{percentage($i/360)} {
      color: hsl($i,100%,75%);
    }
  }
}

@keyframes rainbow-background-shift {
  0%{background-position:76% 0%}
  25%{background-position:50% 25%}
  50%{background-position:25% 100%}
  75%{background-position:50% 50%}
  100%{background-position:76% 0%}
}

* { box-sizing: border-box; }
$duration: 30s;

@-webkit-keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}